document.addEventListener('DOMContentLoaded', function() {
    let mapInitialized = false;

    function initMap() {

        mapboxgl.accessToken =
            'pk.eyJ1IjoiamFja2Vsdmluc3NvbiIsImEiOiJjbG83N2w3MW8wMnFlMnF0Z2Y2anFvNndrIn0.1IK9qjbz6gra085-fbsjVQ';
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/jackelvinsson/clo792f6o001t01pq3rtldpvn',
        });

        map.scrollZoom.disable();
        map.dragPan.disable();
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        map.touchPitch.disable();

        const geojson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [17.013241382949097, 58.75414114516385],
                    },
                    properties: {
                        title: 'Mapbox',
                        description: 'Tankbar AB',
                    },
                },
            ],
        };

// add markers to map
        for (const feature of geojson.features) {
            // create an HTML element for each feature
            const el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .setPopup(
                    new mapboxgl.Popup({offset: 25}) // add popups
                        .setHTML(`<h3 class="m-2">${feature.properties.description}</h3>`),
                )
                .addTo(map);
        }

        const mapbox = document.getElementById('map');
// click event listener for the map. Re-eable scroll zoom when the user clicks on the map
        document.addEventListener('click', function(event) {

            if (event.target === mapbox || mapbox.contains(event.target)) {
                map.scrollZoom.enable();
                map.dragPan.enable();
            }
        });
    }

    function handleScroll() {
        // Check if the user has scrolled to a certain point on the page
        if (window.scrollY > 500 && !mapInitialized) {
            initMap();
            mapInitialized = true;
        }
    }

    function handleEndOfPage() {
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight) {
            initMap();
            mapInitialized = true;
        }
    }

    // Attach the handleScroll function to the scroll event
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('DOMContentLoaded', handleEndOfPage);
});
